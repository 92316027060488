// Export the state object
export default {
  // The default value for selected platforms
  selectedPlatforms: [],

  // The default value for selected influencers
  selectedInfluencers: [],

  // CampaignTracking Detailed Objects
  details: [],

  // CampaignTracking Analytics Objects
  analytics: [],

  // CampaignTracking Query Objects
  result: {
    influencers: [],
    posts: {
      grid: [],
      table: []
    },
    stories: {
      grid: [],
      table: []
    }
  },

  // Query parameters used when making requests
  query: {
    search: "",
    platforms: [],
    status: null,

    perPage: 10,
    page: 1,

    sortBy: "id",
    sortOrder: "desc"
  },

  // API response data
  response: {
    created_by_user: {
      data: [],
      total: 10,
    },

    created_by_team: {
      data: [],
      total: 10,
    },

    shared_with_user: {
      data: [],
      total: 10,
    }
  }
}
