// Export the mutations object
export default {
  /**
   * Update the query parameter values
   *
   * @param {Object} state
   * @param {Object} { key: String, value: String|Integer }
   */
  UPDATE_QUERY(state, { key, value }) {
    state.query[key] = value
  },

  /**
   * Update the API response
   *
   * @param {Object} state
   * @param {Object} payload
   */
  UPDATE_RESPONSE(state, payload) {
    state.response.created_by_user = payload.created_by_user || state.response.created_by_user
    state.response.created_by_team = payload.created_by_team || state.response.created_by_team
    state.response.shared_with_user = payload.shared_with_user || state.response.shared_with_user
  },

  /**
   * Update the value of the detailed object in the local store
   *
   * @param {Object} state
   * @param {Object} payload
   * @returns {void}
   */
  UPDATE_DETAILS(state, payload) {
    // Find if an object for it already exists
    const index = state.details.findIndex((search) => search.model.uuid === payload.model.uuid)

    // If it exists
    if (index !== -1) {
      // Remove it
      state.details.splice(index, 1)
    }

    // Push the object into the store
    state.details.push(payload)
  },

  /**
   * Update the value of the detailed object in the local store
   *
   * @param {Object} state
   * @param {Object} payload
   * @returns {void}
   */
  UPDATE_ANALYTICS(state, payload) {
    // Find if an object for it already exists
    const index = state.analytics.findIndex((search) => search.id === payload.id)

    // If it exists
    if (index !== -1) {
      // Remove it
      state.analytics.splice(index, 1)
    }

    // Push the object into the store
    state.analytics.push(payload)
  },

  /**
   * Replace the model value in local store
   *
   * @param {Object} state
   * @param {Object} payload
   * @returns {void}
   */
  UPDATE_MODEL(state, payload) {
    // Find if the object exists
    const detail = state.details.find((search) => search.model.id === payload.id)

    // If it exists
    if (detail) {
      // Update the model value for it
      detail.model = payload
    }
  },

  /**
   * Add or update the campaign member to the list
   *
   * @param {Object} state
   * @param {Object} payload
   * @returns {void}
   */
  UPDATE_MEMBER(state, payload) {
    // Find the detailed object
    const detail = state.details.find((search) => search.model.id === payload.id)

    // If found the details object
    if (detail) {
      // Check if member exists
      const memberIndex = detail.members.findIndex((search) => search.id === payload.member.id)

      // If found
      if (memberIndex !== -1) {
        // Replace it
        detail.members.splice(memberIndex, 1, payload.member)
      }
      // Otherwise, push a new value
      else {
        detail.members.push(payload.member)
      }
    }
  },

  /**
   * Remove the campaign member from the list
   *
   * @param {Object} state
   * @param {Object} payload
   * @returns {void}
   */
  REMOVE_MEMBER(state, payload) {
    // Find the detailed object
    const detail = state.details.find((search) => search.model.id === payload.id)

    // If found the details object
    if (detail) {
      // Check if member exists
      const memberIndex = detail.members.findIndex((search) => search.id === payload.member.id)

      // If found
      if (memberIndex !== -1) {
        // Remove it
        detail.members.splice(memberIndex, 1)
      }
    }
  },

  /**
   * Decrease the cost value by 1
   *
   * @param {Object} state
   * @param {Number} id
   * @returns {void}
   */
  DECREASE_COST(state, id) {
    // Find if the object exists
    const detail = state.details.find((search) => search.model.id === id)

    // If it exists
    if (detail) {
      // Update the model value for it
      detail.cost--
    }
  },

  /**
   * Update the cost value
   *
   * @param {Object} state
   * @param {Object} payload
   * @returns {void}
   */
  UPDATE_COST(state, payload) {
    // Find if the object exists
    const detail = state.details.find((search) => search.model.id === payload.id)

    // If it exists
    if (detail) {
      // Update the model value for it
      detail.cost = payload.cost
    }
  },

  /**
   * Update the value of selected platform
   *
   * @param {Object} state
   * @param {String} payload
   */
  UPDATE_SELECTED_PLATFORM(state, payload) {
    // Find if the object exists
    const item = state.selectedPlatforms.find((search) => search.id === payload.id)

    // If it exists, update its value
    if (item) {
      item.value = payload.value
    }
    // Otherwise, push a new value
    else {
      state.selectedPlatforms.push({
        id: payload.id,
        value: payload.value
      })
    }
  },

  /**
   * Update the value of selected influencer
   *
   * @param {Object} state
   * @param {String} payload
   */
  UPDATE_SELECTED_INFLUENCER(state, payload) {
    // Find if the object exists
    const item = state.selectedInfluencers.find((search) => search.id === payload.id)

    // If the payload's value is null, delete this
    if (payload.value === null && item) {
      // Find the index
      const index = state.selectedInfluencers.findIndex((search) => search.id === payload.id)

      // Delete the item
      state.selectedInfluencers.splice(index, 1)
    }
    // Otherwise
    else {
      // If it exists, update its value
      if (item) {
        item.value = payload.value
      }
      // Otherwise, push a new value
      else {
        state.selectedInfluencers.push({
          id: payload.id,
          value: payload.value
        })
      }
    }
  },

  /**
   * Update the query value of influencers
   *
   * @param {Object} state
   * @param {Object} payload
   */
  UPDATE_INFLUENCERS_QUERY(state, payload) {
    // Find if the object exists
    const item = state.result.influencers.find((search) => search.id === payload.id)

    // If it exists, update its value
    if (item) {
      item.query = payload.query
    }
    // Otherwise, push a new value
    else {
      state.result.influencers.push({
        id: payload.id,
        query: payload.query,
        response: {
          data: [],
          total: null
        }
      })
    }
  },

  /**
   * Update the response value of influencers
   *
   * @param {Object} state
   * @param {Object} payload
   */
  UPDATE_INFLUENCERS_RESPONSE(state, payload) {
    // Find if the object exists
    const item = state.result.influencers.find((search) => search.id === payload.id)

    // If it exists, update its value
    if (item) {
      item.response.data = payload.response.data
      item.response.total = payload.response.total
    }
    // Otherwise, push a new value
    else {
      state.result.influencers.push({
        id: payload.id,
        query: {
          pagination: {
            page: 1,
            perPage: 10
          },

          sortBy: "most_liked"
        },
        response: {
          data: payload.response.data,
          total: payload.response.total
        }
      })
    }
  },

  /**
   * Update the query value of posts grid
   *
   * @param {Object} state
   * @param {Object} payload
   */
  UPDATE_POSTS_GRID_QUERY(state, payload) {
    // Find if the object exists
    const item = state.result.posts.grid.find((search) => search.id === payload.id)

    // If it exists, update its value
    if (item) {
      item.query = payload.query
    }
    // Otherwise, push a new value
    else {
      state.result.posts.grid.push({
        id: payload.id,
        query: payload.query,
        response: {
          data: [],
          total: null
        }
      })
    }
  },

  /**
   * Update the response value of posts grid
   *
   * @param {Object} state
   * @param {Object} payload
   */
  UPDATE_POSTS_GRID_RESPONSE(state, payload) {
    // Find if the object exists
    const item = state.result.posts.grid.find((search) => search.id === payload.id)

    // Get the items array
    const items = item.query.pagination.page === 1 ? payload.response.data : [...item.response.data, ...payload.response.data]

    // If it exists, update its value
    if (item) {
      item.response.data = items
      item.response.total = payload.response.total
    }
    // Otherwise, push a new value
    else {
      state.result.posts.grid.push({
        id: payload.id,
        query: {
          pagination: {
            page: 1,
            perPage: 10
          },

          sortBy: "most_liked"
        },
        response: {
          data: items,
          total: payload.response.total
        }
      })
    }
  },

  /**
   * Update the query value of posts table
   *
   * @param {Object} state
   * @param {Object} payload
   */
  UPDATE_POSTS_TABLE_QUERY(state, payload) {
    // Find if the object exists
    const item = state.result.posts.table.find((search) => search.id === payload.id)

    // If it exists, update its value
    if (item) {
      item.query = payload.query
    }
    // Otherwise, push a new value
    else {
      state.result.posts.table.push({
        id: payload.id,
        query: payload.query,
        response: {
          data: [],
          total: null
        }
      })
    }
  },

  /**
   * Update the response value of posts table
   *
   * @param {Object} state
   * @param {Object} payload
   */
  UPDATE_POSTS_TABLE_RESPONSE(state, payload) {
    // Find if the object exists
    const item = state.result.posts.table.find((search) => search.id === payload.id)

    // If it exists, update its value
    if (item) {
      item.response.data = payload.response.data
      item.response.total = payload.response.total
    }
    // Otherwise, push a new value
    else {
      state.result.posts.table.push({
        id: payload.id,
        query: {
          pagination: {
            page: 1,
            perPage: 10
          },

          sortBy: "most_liked"
        },
        response: {
          data: payload.response.data,
          total: payload.response.total
        }
      })
    }
  },

  /**
   * Update the query value of stories grid
   *
   * @param {Object} state
   * @param {Object} payload
   */
  UPDATE_STORIES_GRID_QUERY(state, payload) {
    // Find if the object exists
    const item = state.result.stories.grid.find((search) => search.id === payload.id)

    // If it exists, update its value
    if (item) {
      item.query = payload.query
    }
    // Otherwise, push a new value
    else {
      state.result.stories.grid.push({
        id: payload.id,
        query: payload.query,
        response: {
          data: [],
          total: null
        }
      })
    }
  },

  /**
   * Update the response value of stories grid
   *
   * @param {Object} state
   * @param {Object} payload
   */
  UPDATE_STORIES_GRID_RESPONSE(state, payload) {
    // Find if the object exists
    const item = state.result.stories.grid.find((search) => search.id === payload.id)

    // Get the items array
    const items = item.query.pagination.page === 1 ? payload.response.data : [...item.response.data, ...payload.response.data]

    // If it exists, update its value
    if (item) {
      item.response.data = items
      item.response.total = payload.response.total
    }
    // Otherwise, push a new value
    else {
      state.result.stories.grid.push({
        id: payload.id,
        query: {
          pagination: {
            page: 1,
            perPage: 10
          },

          sortBy: "most_liked"
        },
        response: {
          data: items,
          total: payload.response.total
        }
      })
    }
  },

  /**
   * Update the query value of stories table
   *
   * @param {Object} state
   * @param {Object} payload
   */
  UPDATE_STORIES_TABLE_QUERY(state, payload) {
    // Find if the object exists
    const item = state.result.stories.table.find((search) => search.id === payload.id)

    // If it exists, update its value
    if (item) {
      item.query = payload.query
    }
    // Otherwise, push a new value
    else {
      state.result.stories.table.push({
        id: payload.id,
        query: payload.query,
        response: {
          data: [],
          total: null
        }
      })
    }
  },

  /**
   * Update the response value of stories table
   *
   * @param {Object} state
   * @param {Object} payload
   */
  UPDATE_STORIES_TABLE_RESPONSE(state, payload) {
    // Find if the object exists
    const item = state.result.stories.table.find((search) => search.id === payload.id)

    // If it exists, update its value
    if (item) {
      item.response.data = payload.response.data
      item.response.total = payload.response.total
    }
    // Otherwise, push a new value
    else {
      state.result.stories.table.push({
        id: payload.id,
        query: {
          pagination: {
            page: 1,
            perPage: 10
          },

          sortBy: "most_liked"
        },
        response: {
          data: payload.response.data,
          total: payload.response.total
        }
      })
    }
  }
}
