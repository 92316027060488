// Common repetitive text values
export const unifiedBalance = "unified-balance"
export const influencerDiscovery = "influencer-discovery"
export const influencerInsight = "influencer-insight"

export const paidCollaborations = "paid-collaborations"
export const audienceOverlap = "audience-overlap"
export const influencerTieBreaker = "influencer-tie-breaker"
export const paidComparison = "paid-comparison"

export const competitorCheck = "competitor-check"
export const campaignPlanning = "campaign-planning"
export const socialSentiments = "social-sentiments"

export const mentionTracking = "mention-tracking"
export const campaignTracking = "campaign-tracking"
export const influencerGroups = "influencer-group"
export const influencerOutreach = "influencer-outreach"

// Actions
export const actionContentDiscovery = "action-content-discovery"
export const actionMassDiscoveryDownload = "action-mass-discovery-download"
export const actionUnlimitedDiscoveryView = "action-unlimited-discovery-view"

export const actionBulkExportInfluencerInsightsPDF = "action-bulk-export-influencer-insights-pdf"
export const actionBulkExportInfluencerInsightsXLSX = "action-bulk-export-influencer-insights-xlsx"

// Filters
export const filterDiscoveryEthnicity = "filter-discovery-ethnicity"

// Platforms
export const platformDiscoveryTwitter = "platform-discovery-twitter"
export const platformDiscoverySnapchat = "platform-discovery-snapchat"
export const platformDiscoveryLinkedIn = "platform-discovery-linkedin"

export default {
  conversionRate: 100,

  model: {
    user: {
      roles: {
        admin: "admin",
        moderator: "moderator",
        endUser: "end-user"
      },

      creditTypes: {
        split: "split",
        unified: "unified"
      },

      allowedServices: {
        // Services
        influencerDiscovery,
        influencerInsight,
        paidCollaborations,
        audienceOverlap,
        influencerTieBreaker,
        paidComparison,

        competitorCheck,
        socialSentiments,
        campaignPlanning,

        // CRM
        mentionTracking,
        campaignTracking,
        influencerGroups,
        influencerOutreach,

        // Actions
        actionContentDiscovery,
        actionMassDiscoveryDownload,
        actionUnlimitedDiscoveryView,
        actionBulkExportInfluencerInsightsPDF,
        actionBulkExportInfluencerInsightsXLSX,

        // Filters
        filterDiscoveryEthnicity,

        // Platforms
        platformDiscoveryTwitter,
        platformDiscoverySnapchat,
        platformDiscoveryLinkedIn
      },

      metas: {
        discoveryExportsLimit: "discovery-exports-limit",

        popup: {
          info: {
            influencerDiscovery: "popup-info-influencer-discovery",
            influencerInsight: "popup-info-influencer-insight",
          }
        },

        email: {
          influencerDiscoveryExportReady: "email-influencer-discovery-export-ready",
          influencerInsightExportReady: "email-influencer-insight-export-ready",
          paidCollaborationExportReady: "email-paid-collaboration-export-ready",
          audienceOverlapReady: "email-audience-overlap-ready",
          socialSentimentReady: "email-social-sentiment-ready",
          competitorCheckReady: "email-competitor-check-ready",
          contentDiscoveryReady: "email-content-discovery-ready",
          influencerGroupInfluencersImported: "email-influencer-group-influencers-imported",
          campaignTrackingInfluencersImported: "email-campaign-tracking-influencers-imported",
          mentionTrackingReady: "email-mention-tracking-ready",
          reportShared: "email-report-shared",
        }
      }
    },

    transaction: {
      type: {
        debit: "debit",
        credit: "credit"
      },

      for: {
        influencerDiscovery,
        influencerInsight,
        audienceOverlap,
        unifiedBalance
      }
    },

    unlockedAccount: {
      for: {
        influencerDiscovery,
        influencerInsight
      },

      cost: {
        influencerDiscovery: 4,
        influencerInsight: 100
      }
    },

    generatedReport: {
      type: {
        influencerDiscovery,
        influencerInsight,
      }
    },

    temporaryFile: {
      for: {
        influencerInsight
      }
    },

    campaign: {
      status: {
        failed: "failed",
        pending: "pending",
        cancelled: "cancelled",
        completed: "completed",
        inProgress: "in-progress"
      },

      crawlingStatus: {
        failed: "failed",
        pending: "pending",
        completed: "completed",
        inProgress: "in-progress"
      },

      dictionary: {
        status: {
          "failed": "Failed",
          "pending": "Pending",
          "cancelled": "Cancelled",
          "completed": "Completed",
          "in-progress": "In Progress"
        }
      },

      colors: {
        status: {
          "failed": "red",
          "pending": "orange",
          "cancelled": "red",
          "completed": "green",
          "in-progress": "light-blue"
        }
      },

      gender: {
        all: "all",
        male: "male",
        female: "female",
        others: "others"
      },

      hashtagCondition: {
        any: "any",
        all: "all"
      }
    },

    influencerInsightExport: {
      maximumItems: 200,
    },

    audienceOverlapReport: {
      minimumItems: 2,
      maximumItems: 20
    }
  },

  status: {
    pending: "pending",
    processing: "processing",
    completed: "completed",
    failed: "failed"
  }
}
